@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.heading{
    text-align: center;
    font-family: 'Pacifico', cursive;
    font-weight: bolder;
    font-size: 55px;
}

.input-field{
    margin: 7px !important;
}
.input-field label {
    margin-top: 25px;
    font-size: 18px !important;
}

.input-field input{
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgb(147, 133, 255);
    font-size: 20px;
    font-family: Tahoma;
    margin-bottom: 10px;
    
}
.sign {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 25px;
    margin: 35px;
    width: auto;
    height: auto;
}

.formDiv{
    background: linear-gradient(#e3ffc4 0%, #b0effe 100%);
    margin: 20px;
    border-radius: 30px;
    box-shadow: 5px 10px 5px #888888;
    padding: 50px !important;
    /* border: 2px solid black; */
}